import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {useLocation, matchPath} from 'react-router-dom';
import clsx from 'clsx';

import useStore from './useStore';
import Spotlight from './Spotlight';
import Logo from './Logo';
import Nav from './Nav';
import Footer from './Footer';
import {borderB, useGlobalStyles} from './utils';

const Layout = ({datas, children}) => {
  const {pathname} = useLocation();
  const classes = {...useGlobalStyles(), ...useStyles()};
  const hideMenus = useStore(s => s.hideMenus);
  const set = useStore(s => s.set);
  const [backdrop, setBackdrop] = useState('init');

  const notRoot = matchPath(pathname, {path: '/:id'});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]); // eslint-disable-line

  useEffect(() => {
    setBackdrop('shown');

    set({hideMenus: false});
  }, [pathname, set]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const showing = scrollTop >= 0 && scrollTop + windowHeight <= documentHeight - 500;

      if (backdrop === 'init' && showing) setBackdrop('shown');
      if (backdrop === 'shown' && !showing) setBackdrop('hidden');
      if (backdrop === 'hidden' && showing) setBackdrop('shown');
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [backdrop]);

  if (!datas) return null;

  const img = datas.image_logo[1];

  return (
    <>
      <Box
        minWidth={320}
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        className={classes.fadein}
      >
        {!notRoot && (
          <Box
            position="fixed"
            top={0}
            left={0}
            width={1}
            height={1}
            minHeight={320}
            pt={2}
            pb={{xs: 2, md: 10}}
            px={{xs: 2, md: 12}}
            display="flex"
            flexDirection="column"
            className={clsx({
              [classes.backdrop]: backdrop === 'init',
              [classes.fadein]: backdrop === 'shown',
              [classes.fadeout]: backdrop === 'hidden',
            })}
          >
            <Box
              flexGrow={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
            >
              {!!img && (
                <Box
                  component="img"
                  p={2}
                  width={800}
                  maxWidth={1}
                  maxHeight={1}
                  alt={img.origin.description}
                  src={img.origin.httpUrl}
                />
              )}
            </Box>
          </Box>
        )}
        {!notRoot && (
          <Spotlight
            title={datas.text_spotlight_title}
            subtitle={datas.text_spotlight_subtitle}
            link={datas.page_link}
          />
        )}
        <Box
          component="nav"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pb={4}
        >
          <Box width={1} pl={{xs: 2, md: 12}} pr={4}>
            <Box pt={2}>
              <Logo img={datas.image_logo[0]} />
            </Box>
          </Box>
          {!hideMenus && <Nav pages={datas.children} border={notRoot} />}
        </Box>
        {notRoot && <Box {...borderB} displayPrint="none" />}
        <Box component="main" position="relative" flexGrow={1} width={1} {...borderB} {...{pb: 17}}>
          {children}
        </Box>
      </Box>
      {!hideMenus && (
        <Box component="footer" minWidth={320}>
          <Footer
            images={datas.image_footer}
            text={datas.text_footer}
            links={datas.page_footer_links}
          />
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  backdrop: {
    opacity: 0,
  },
  fadein: {
    animation: '$fadein ease-in-out 500ms',
    animationFillMode: 'forwards',
  },
  '@keyframes fadein': {
    '0%': {opacity: 0},
    '50%': {opacity: 0},
    '100%': {opacity: 1},
  },
  fadeout: {
    animation: '$fadeout ease-in-out 500ms',
    animationFillMode: 'forwards',
  },
  '@keyframes fadeout': {
    '0%': {opacity: 1},
    '50%': {opacity: 0},
    '100%': {opacity: 0},
  },
}));

export default Layout;
