import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import Link from './Link';
import {useGlobalStyles} from './utils';

const Home = ({datas}) => {
  const {t} = useTranslation();
  const classes = {...useGlobalStyles(), ...useStyles()};

  if (!datas) return null;

  const highlights = datas.repeater_highlights;
  const colors = ['#d5d1c3', '#c19092', '#9ba7bb', '#a3bb9b'];
  const color = () => colors[Math.floor(Math.random() * colors.length)];

  return (
    <Box px={{xs: 2, md: 12}} pt={8}>
      {highlights.map((highlight, idx) => {
        const image = datas.images.find(image =>
          image.origin.tags.split(' ').includes(highlight.fields.text_highlight_thumbnail_tag)
        );
        const c = color();
        return (
          <Box
            key={idx}
            className={`${classes.container} ${idx !== 0 ? classes.offset : ''}`}
            style={{justifyContent: idx % 2 > 0 ? 'flex-end' : 'flex-start'}}
          >
            <Card variant="outlined" className={classes.card}>
              <CardActionArea
                component={Link}
                href={highlight.fields.text_highlight_link}
                disableRipple
                focusVisibleClassName={classes.focus}
                className={classes.hover}
              >
                {!!image && (
                  <Box position="relative">
                    <CardMedia image={image.big.httpUrl} className={classes.media} />
                    <Box
                      className={clsx(classes.overlay, 'overlay')}
                      style={{backgroundColor: c}}
                    />
                  </Box>
                )}
                <CardContent>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: highlight.fields.text_highlight_description.formatted,
                    }}
                    className={clsx(classes.markdown, 'highlight')}
                    style={{color: c}}
                  />
                </CardContent>
              </CardActionArea>
              <CardActions>
                <ButtonBase
                  component={Link}
                  href={highlight.fields.text_highlight_link}
                  size="small"
                  disableRipple
                  focusVisibleClassName={classes.focus}
                  className={classes.hover}
                  style={{marginLeft: 'auto', marginRight: '8px', color: c}}
                >
                  <Typography>{t('main.more').toUpperCase()}</Typography>
                </ButtonBase>
              </CardActions>
            </Card>
          </Box>
        );
      })}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  offset: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('lg')]: {
      marginTop: -theme.spacing(16),
    },
  },
  card: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: '640px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 calc(50% - 680px)',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    mixBlendMode: 'screen',
    opacity: 0.5,
    transition: 'opacity 0.3s ease',
  },
  button: {
    color: theme.palette.primary.main,
  },
}));

export default Home;
