import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

import {useGlobalStyles} from './utils';

const filterByTag = (images, tag) => {
  return images.filter(image => image.origin.tags.split(' ').includes(tag));
};

const Gallery = ({images}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tag = searchParams.get('tag');
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const classes = {...useGlobalStyles(), ...useStyles()};

  const filtered = filterByTag(images, tag);

  const expand = index => {
    setIndex(index);
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  const next = () => {
    setIndex(prevIndex => (prevIndex + 1) % filtered.length);
  };

  const prev = () => {
    setIndex(prevIndex => (prevIndex - 1 + filtered.length) % filtered.length);
  };

  if (!images || !tag) {
    return (
      <Box pt={10} px={{xs: 2, md: 12}}>
        <Typography>{t('main.notfound')}</Typography>
      </Box>
    );
  }

  return (
    <Box maxWidth={1280} px={{xs: 2, md: 12}} py={2}>
      <Grid container spacing={2}>
        {filtered.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} key={image.name}>
            <img
              src={image.small.httpUrl}
              alt={image.name}
              className={classes.item}
              onClick={e => {
                expand(index);
                e.target.blur();
              }}
              onKeyUp={e => {
                if (e.key !== 'Enter') return;
                expand(index);
                e.target.blur();
              }}
              tabIndex={0}
            />
          </Grid>
        ))}
      </Grid>

      <Dialog fullScreen open={open} onClose={close}>
        <DialogContent className={classes.dialog}>
          {filtered[index] && (
            <>
              <Box className={classes.container}>
                <img
                  src={filtered[index].big.httpUrl}
                  alt={filtered[index].name}
                  className={classes.image}
                />
              </Box>

              <IconButton
                onClick={close}
                size="small"
                disableRipple
                focusVisibleClassName={classes.focus}
                className={clsx(classes.hover, classes.close)}
              >
                <CloseIcon className="icon" />
              </IconButton>

              <IconButton
                onClick={prev}
                size="small"
                disableRipple
                focusVisibleClassName={classes.focus}
                className={clsx(classes.hover, classes.prev)}
              >
                <ArrowBack className="icon" />
              </IconButton>

              <IconButton
                onClick={next}
                size="small"
                disableRipple
                focusVisibleClassName={classes.focus}
                className={clsx(classes.hover, classes.next)}
              >
                <ArrowForward className="icon" />
              </IconButton>

              <Typography className={classes.counter}>
                {index + 1} / {filtered.length}
              </Typography>

              <Typography
                display="block"
                className={clsx(classes.hover, classes.focus, classes.caption)}
                tabIndex={0}
              >
                {filtered[index].origin.description}
              </Typography>
            </>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  item: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.2s',
    '&:hover, &:focus': {
      transform: 'scale(1.03)',
    },
  },
  dialog: {
    minWidth: 320,
    overflowX: 'auto',
    position: 'relative',
    padding: '0',
    '&:first-child': {
      paddingTop: '0',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
    padding: '4em 32px 4em 32px',
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 2,
  },
  prev: {
    position: 'absolute',
    top: '50%',
    left: 3,
    transform: 'translateY(-50%)',
  },
  next: {
    position: 'absolute',
    top: '50%',
    right: 3,
    transform: 'translateY(-50%)',
  },
  counter: {
    position: 'absolute',
    top: 15,
    left: 15,
    fontSize: '1.16rem',
    fontWeight: 'bold',
    lineHeight: '1.1em',
  },
  caption: {
    position: 'absolute',
    bottom: 10,
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    width: '100%',
    padding: '8px 16px',
    fontSize: '1.16rem',
    fontWeight: 'bold',
    lineHeight: '1.1em',
    cursor: 'pointer',
  },
}));

export default Gallery;
