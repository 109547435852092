import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import {useHistory, useLocation} from 'react-router-dom';

import {useGlobalStyles} from './utils';

const Nav = ({pages}) => {
  const history = useHistory();
  const location = useLocation();
  const classes = {...useGlobalStyles(), ...useStyles()};

  const active = path => path === location.pathname;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      pt={4}
      pr={{xs: 2, md: 12}}
      displayPrint="none"
    >
      {pages
        .filter(p => !p.isHidden)
        .map(page => (
          <ButtonBase
            key={page.path}
            component="a"
            disableRipple
            href={page.path}
            onClick={e => {
              if (e.metaKey === false) {
                e.preventDefault();
                history.push(page.path);
              }
            }}
            focusVisibleClassName={classes.focus}
            className={classes.hover}
          >
            <Typography
              noWrap
              className={clsx(classes.manier, active(page.path) && classes.active)}
            >
              {page.title.toUpperCase()}
            </Typography>
          </ButtonBase>
        ))}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  active: {
    color: theme.palette.primary.main,
  },
  nav: {
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export default Nav;
