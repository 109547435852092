import React, {useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';

import {read, useGlobalStyles} from './utils';

const Text = page => {
  const {section = false, first = false, path} = page;
  const [datas, setDatas] = useState();
  const classes = useGlobalStyles();

  useEffect(() => {
    (async () => setDatas(await read(path)))();
  }, []); // eslint-disable-line

  if (!datas) return null;

  // mt="-1px" : for section dividers to be hidden under subnav
  let padding = 0;
  if (!section) padding = 10;
  if (first) padding = 4;

  return (
    <Box mt="-1px" pt={padding} px={{xs: 2, md: 12}} className={classes.fadein}>
      <Box
        width={1}
        maxWidth={800}
        dangerouslySetInnerHTML={{__html: datas.text_block.formatted}}
        className={classes.markdown}
      />
    </Box>
  );
};

export default Text;
