import React from 'react';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import CallMadeIcon from '@material-ui/icons/CallMade';
import {useHistory} from 'react-router-dom';

import {useGlobalStyles} from './utils';

const Spotlight = ({title, subtitle, link}) => {
  const history = useHistory();
  const classes = useGlobalStyles();

  if (!link) return null;

  return (
    <ButtonBase
      component="a"
      disableRipple
      href={link.path}
      onClick={e => {
        if (e.metaKey === false) {
          e.preventDefault();
          history.push(link.path);
        }
      }}
      focusVisibleClassName={classes.focus}
      className={classes.hover}
    >
      <Box
        width={1}
        px={{xs: 2, md: 12}}
        py={2}
        display="flex"
        flexDirection={{xs: 'column', sm: 'row'}}
        color="common.white"
        bgcolor="text.primary"
        className="spotlight"
      >
        <Box display="flex" flexShrink={0} pr={2}>
          <Typography className={classes.bold}>{title}</Typography>
        </Box>
        <Box display="flex">
          <Typography className={classes.bold}>{subtitle}</Typography>
        </Box>
        <Box
          pl={4}
          flexGrow={1}
          display="flex"
          flexWrap="no-wrap"
          justifyContent="flex-end"
          alignItems="center"
        >
          <CallMadeIcon fontSize="small" style={{marginLeft: 4}} />
        </Box>
      </Box>
    </ButtonBase>
  );
};

export default Spotlight;
