import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import Multiple from './Multiple';
import Text from './Text';
import Image from './Image';
import File from './File';
import Contact from './Contact';
import Temples from './Temples';
import Temple from './Temple';
import Archive from './Archive';

const endpoint = process.env.REACT_APP_API_URL;
export const create = async (url = '', data = {}) => {
  const response = await fetch(`${endpoint}${url}`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data),
  });
  return response.json();
};
export const read = async url => {
  const response = await fetch(`${endpoint}${url}`);

  if (response.status === 503) return 'protected';
  return response.json();
};

export const getComponent = page => {
  switch (page.template) {
    case 'temples':
      return <Temples {...page} />;
    case 'temple':
      return <Temple {...page} />;
    case 'archive':
      return <Archive {...page} />;
    case 'multiple':
      return <Multiple {...page} />;
    case 'text':
      return <Text {...page} />;
    case 'image':
      return <Image {...page} />;
    case 'file':
      return <File {...page} />;
    case 'contact':
      return <Contact {...page} />;
    default:
      return null;
  }
};

export const useGlobalStyles = makeStyles(theme => ({
  fadein: {
    animation: '$fadein ease 1s',
  },
  '@keyframes fadein': {
    '0%': {opacity: 0},
    '50%': {opacity: 0},
    '100%': {opacity: 1},
  },
  manier: {
    fontFamily: 'Manier-Bold',
    letterSpacing: '0.05em',
    color: theme.palette.text.primary,
  },
  bold: {
    fontWeight: 'bold',
  },
  markdown: {
    overflow: 'hidden',
    '& a': {
      color: theme.palette.text.primary,
    },
    '& a:hover': {
      color: theme.palette.primary.main,
    },
    '& a:focus': {
      color: theme.palette.primary.main,
      outline: 'none',
    },
    '& > *': {
      margin: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    '& > h1': {
      fontWeight: 'normal',
      fontSize: theme.typography.h6.fontSize,
      marginBottom: theme.spacing(4),
    },
    '& > h2': {
      fontSize: theme.typography.fontSize,
      fontWeight: 'bold',
      marginBottom: theme.spacing(),
    },
    '& > h3': {
      fontSize: theme.typography.fontSize,
      fontWeight: 'normal',
      fontStyle: 'italic',
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(),
      },
    },
    '& > h4': {
      fontSize: theme.typography.h4.fontSize,
      fontWeight: 'bold',
      marginBottom: theme.spacing(4),
    },
    '& > h5': {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: 'bold',
    },
    '& > h6': {
      fontSize: '.8rem',
      color: theme.palette.text.secondary,
    },
    '& > hr': {
      border: 'solid 1px',
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      borderColor: theme.palette.divider,
      marginBottom: theme.spacing(4),
    },
    '& > p': {
      fontSize: theme.typography.fontSize,
      marginBottom: theme.spacing(4),
    },
    '& > p:has(.grow)': {
      display: 'flex',
      '& .grow': {
        flexGrow: 1,
      },
    },
    '& > .space)': {
      display: 'flex',
      flexGrow: 1,
    },
    '& > ul': {
      listStyle: 'square outside none',
      paddingLeft: theme.spacing(3),
      marginBottom: theme.spacing(4),
    },
    '& > .ip': {
      '& + p': {
        marginBottom: theme.spacing(),
      },
      [theme.breakpoints.up('sm')]: {
        float: 'left',
        width: '35%',
        clear: 'both',
        fontStyle: 'normal',
        '& + p': {
          float: 'left',
          width: '65%',
          paddingLeft: theme.spacing(),
        },
        '& + p + *': {
          clear: 'both',
        },
      },
    },
  },
  focus: {
    '&:focus': {
      mixBlendMode: 'normal',
      color: theme.palette.primary.main,
      outline: 'none',
    },
    '& > p,  & > h3, & > h4, & > span': {
      color: theme.palette.primary.main,
    },
    '& .logo svg *, & .icon': {
      fill: theme.palette.primary.main,
    },
    '& .spotlight': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .spotlight *': {
      color: theme.palette.common.white,
    },
    '& .temples > span > span': {
      borderColor: theme.palette.primary.main + '!important',
      backgroundColor: theme.palette.primary.main + '!important',
    },
    '& .temples > span > span > span': {
      color: theme.palette.background.default + '!important',
    },
    '& .temples > span': {
      color: theme.palette.primary.main + '!important',
    },
    '& .highlight': {
      color: theme.palette.primary.main + '!important',
    },
    '& .overlay': {
      opacity: 0,
    },
  },
  hover: {
    '&:hover': {
      mixBlendMode: 'normal',
      color: theme.palette.primary.main,
    },
    '&:hover > p, &:hover > h3, &:hover > h4, &:hover > span': {
      color: theme.palette.primary.main,
    },
    '&:hover .logo svg *, &:hover .icon': {
      fill: theme.palette.primary.main,
    },
    '&:hover .spotlight': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:hover .spotlight *': {
      color: theme.palette.common.white,
    },
    '&:hover .highlight': {
      color: theme.palette.primary.main + '!important',
    },
    '&:hover .overlay': {
      opacity: 0,
    },
  },
}));

const border = {borderColor: 'divider', border: 1};
export const borderT = {...border, borderLeft: 0, borderRight: 0, borderBottom: 0};
export const borderB = {...border, borderLeft: 0, borderRight: 0, borderTop: 0};
